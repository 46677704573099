@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.page-container {
  width: 100%;
  min-height: 100vh;

  display: flex;
}
.navigation-container {
  padding: 40px 35px;
  display: none;
  height: 100vh;
  background-color: rgb(255, 255, 255);
  transition: 0.2s ease-in-out;
  position: sticky;
  top: 0px;
  left: 0px;
}
.content-container {
  width: 100%;
  min-height: 100vh;
  background-color: rgb(255, 255, 255);
  padding: 35px 30px;

  position: relative;
}

.login-container {
  padding: 40px 35px;
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.login-logo {
  width: 80px;
  align-self: flex-end;
}
.svg-1 {
  position: absolute;
  top: 100%;
  left: 100%;
  transform: translate(-100%, -100%);
}
.svg-2 {
  position: absolute;
  top: 30%;
  left: 5%;
  transform: translate(-50%, -50%);
}
.login-form-container {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  z-index: 1;
}
.login-heading {
  font-weight: 500;
  text-align: center;
  margin-bottom: 35px;
}
.input-container {
  min-height: 65px;
  margin-bottom: 20px;
  width: 100%;
}
.min-height-30 {
  min-height: 30px !important;
}

.height-100 {
  height: 100%;
}

.error-text-custom {
  font-size: 13px;
  color: red;
  text-align: center;
  margin-top: 45px;
  z-index: 10;
}
.edit-error-text {
  font-size: 12px;
  color: red;
}
.label {
  font-size: 16px;
  font-weight: 500;
  padding: 0px 0px 0px 16px;
  position: relative;

  display: flex;
  align-items: center;

  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

.camp-type-label {
  font-size: 16px;
  font-weight: 500;
  padding: 0px 12px 0px 8px;
  position: relative;

  display: flex;
  align-items: center;

  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none;
}

.instruction-checkbox {
  font-size: 14px;
  font-weight: 450;
  padding: 0px 12px 0px 8px;
  position: relative;

  display: flex;
  align-items: center;

  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none;
}

.width-100 {
  width: 100%;
}

.input-with-logo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;

  margin-top: 5px;

  width: 100%;
  height: 45px;
  padding: 5px 16px;
  border-radius: 8px;
  box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.19);
}
.login-input {
  border: none;
  outline: none;
  font-size: 14px;
  width: 85%;
  color: #626262;
  font-weight: 600;
  caret-color: tomato;
  padding: 0px 8px;
}
.color-gray {
  color: #626262;
}
.login-input::placeholder {
  color: rgb(226, 226, 226);
}
.icon {
  height: 70%;
  transition: all 0.3s ease-in-out;

  pointer-events: none;
}
.svg-icon {
  width: 30px;
}
.error-text {
  font-size: 11px;
  color: rgb(255, 61, 61);
  padding: 8px;

  position: absolute;
}
.submit-error-text {
  font-size: 12px;
  color: rgb(255, 61, 61);
  padding: 8px;
  text-align: center;
}
.check-box-container {
  display: flex;
  align-items: flex-start;
}
.light-color {
  color: #626262;
}
.margin-none {
  margin: 0px;
}
.padding-top-bottom-none {
  padding-top: 0px;
  padding-bottom: 0px;
}
.login-button {
  margin-top: auto;
  background-image: linear-gradient(to right, #a63eff, #515efd);
  z-index: 1;
}
.check-box {
  margin-right: 8px;
}
.custom-check-box {
  width: 20px;
  height: 20px;
  background-color: rgb(255, 255, 255);
  position: absolute;
  border-radius: 5px;
  box-shadow: 0px 5px 7px rgba(0, 0, 0, 0.19);
  cursor: pointer;
  padding: 2px;

  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}
.large-screen {
  display: none;
}

.label-tooltip-icon {
  width: 18px;
  height: 18px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  color: gray;
  font-size: 13px;
  border-radius: 50%;
  user-select: none;
  box-shadow: 0px 3px 3px rgb(202, 202, 202);
  border: 2px solid gray;
  margin-left: 10px;
  font-weight: 600;
}
.label-width-tooltip-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}
.c-label-tooltip {
  position: absolute;
  left: 140px;
  top: 73%;
  transform: translate(-50%, -50%);
}
.label-tooltip-content {
  position: absolute;
  left: 20px;
  top: -10px;
  transform: translate(0%, -100%);
  background-image: linear-gradient(135deg, #6a62d2, white);
  color: white;
  font-size: 13px;
  padding: 10px;
  width: 290px;
  border-radius: 10px;
  box-shadow: 0px 6px 6px rgb(204, 204, 204);
  transition: all 200ms ease-in-out;
  border: 2px solid white;
  visibility: hidden;
  opacity: 1;
}
.label-tooltip-icon:hover ~ .label-tooltip-content {
  visibility: visible;
  opacity: 1;
}

.user-create-page-container,
.copd-form-container,
.antibiotic-form-container,
.create-camp-form,
.participant-list-conainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.align-self-center {
  align-self: center;
}
.max-width-850 {
  max-width: 850px;
}
.user-create-input {
  width: 100%;
}
.pointer-event-none {
  pointer-events: none;
}
.camp-card-list-container {
  padding-left: 15px;
  list-style-type: number;
  overflow: auto;
}

.d-flex {
  display: flex;
  width: 100%;
}
.flex-row {
  flex-direction: row;
  align-items: flex-start;
}
.flex-wrap {
  flex-wrap: wrap;
}
.justify-content-between {
  justify-content: space-between;
}
.button {
  border: 2px solid white;
  outline: none;
  border-radius: 8px;
  box-shadow: 0px 5px 7px rgba(0, 0, 0, 0.19);
  height: 50px;
  background-color: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
}
.without-doctor-text {
  font-size: 13px;
  color: red;
  font-weight: 600;
}

.link-tag {
  text-decoration: none;
  color: black;
  width: 100%;
}
.login-button-link {
  text-decoration: none;
  width: 100%;
  margin-top: auto;
}

.menu-icon-with-apollo-logo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  width: 100%;
  z-index: 1;
}
.menu-container {
  width: 32px;
  height: 32px;

  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  align-content: space-between;
  flex-wrap: wrap;
  cursor: pointer;
  z-index: 1;
}
.box {
  width: 15px;
  height: 15px;
  border: 2px solid black;
  border-radius: 5px;
}
.small-apollo-logo {
  width: 75px;
}
.heading-with-icon-container {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  justify-content: space-between;
}
.heading {
  font-size: 24px;
  margin-right: 8px;
  display: flex;
  align-items: center;
}
.heading-icon {
  width: 30px;
}
.sub-heading-icon {
  width: 25px;
}
.card-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-content: space-between;
  flex-wrap: wrap;
  min-height: 228px;
}
.card {
  min-width: 150px;
  width: 49%;
  max-width: 180px;
  height: 110px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: 8px;

  transition: all 0.2s ease-in-out;
  cursor: pointer;

  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.19);
}
.card:hover {
  transform: scale(1.06);
}
.card-1 {
  background-image: linear-gradient(135deg, #ff6cfa, #48e3ff);
}
.card-2 {
  background-image: linear-gradient(135deg, #fc699c, #fdee3a);
}
.card-3 {
  background-image: linear-gradient(135deg, #fd51a0, #6ac0ff);
}
.card-value {
  font-size: 22px;
  font-weight: 700;
}
.card-text {
  font-size: 13px;
  text-align: center;
  color: white;
}
.large-apollo-logo {
  width: 65px;
  margin-bottom: 50px;
}

.max-width-300 {
  max-width: 300px;
}
.nav-option-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 75px;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 15px;

  cursor: pointer;
  transition: all 150ms ease-in-out;
}

.nav-option-container-padding {
  padding: 0px;
}
.nav-option-container:hover,
.logout-button:hover {
  transform: scale(1.02);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}
.nav-option-text {
  font-size: 11px;

  pointer-events: none;
}
.nav-option-icon {
  width: 25px;
  pointer-events: none;
  margin-bottom: 8px;
}
.logout-button {
  width: 100%;
  height: 65px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  cursor: pointer;
  transition: all 200ms ease-in-out;
  background-image: linear-gradient(135deg, #8e8ac5, #6a62d2);
}
.button-text {
  font-size: 16px;
  /* margin-right: 8px; */
}
.hover-menu-container {
  display: flex;
  flex-direction: column;

  position: fixed;
  top: 0px;
  left: 0px;
  transform: translateX(-100%);
  transition: 0.4s;
  z-index: 2;

  padding: 35px 25px;

  width: 70%;
  height: 100vh;
  max-width: 400px;
  background-color: white;
}
.back-button-container {
  display: flex;
  align-items: center;
  margin-bottom: 45px;
}
.margin-top-auto {
  margin-top: auto !important;
}
.about-heading-text {
  font-size: 13px;
  color: #626262;
  font-weight: 500;

  position: relative;
  bottom: 15px;
}
.sub-heading-with-button-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
  margin-bottom: 30px;
}
.sub-heading-with-logo {
  display: flex;
  align-items: center;
}
.justify-content-center {
  justify-content: center;
}

.border {
  border: 1px solid red;
}
.sub-heading {
  font-size: 18px;
  margin-right: 8px;
}
.add-button {
  padding: 0px 20px;
  background-image: linear-gradient(135deg, #ff198c, #ff883e);
  font-size: 13px;
}
.max-width-400 {
  max-width: 400px;
}
.user-list-container {
  display: flex;
  flex-direction: column;
}
.details-cards-container {
  align-self: center;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
}
.details-card-container {
  width: 100%;
  max-width: 350px;
  padding: 20px;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.19);
  border-radius: 8px;
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.align-items-center {
  align-items: center;
}
.title {
  font-size: 11px;
  color: #626262;
}
.display-flex {
  display: flex;
}
.content {
  font-size: 13px;
  font-weight: 600;
  text-transform: capitalize;
}
.executive-style,
.doctor-style {
  width: 50%;
}
.text-lower {
  text-transform: lowercase !important;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.font-12 {
  font-size: 12;
}
.checkbox-container {
  display: flex;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.modified-error-el {
  margin: 0px;
  padding: 0px;
}

.check-box {
  margin: 0px !important;
  margin-right: 7px !important;
}

.check-box-label {
  font-size: 13px !important;
  padding: 0px;
}

.camp-complected-note {
  font-size: 18px;
  font-weight: 600;
  color: rgb(255, 78, 78);
  margin-bottom: 25px;
  text-align: center;
}
.text-right {
  text-align: right;
}
.card-content-gap {
  margin-bottom: 8px;
}
.width-100 {
  width: 100%;
}
.width-65 {
  width: 65%;
}
.width-35 {
  width: 35%;
}
.width-48 {
  width: 48% !important;
}
.width-55 {
  width: 55%;
}
.width-45 {
  width: 45%;
}
.overflow-x-auto {
  overflow-x: auto;
}

.edit-button {
  font-size: 13px;
  width: 68px;
  background-image: linear-gradient(to right, #9795ba, #6a62d2);
}
.inactive-button {
  font-size: 13px;
  width: 68px;
  background-image: linear-gradient(to right, #ff3419, #a50000);
}

.form-edit-button {
  font-size: 13px;
  width: 90px;
  background-image: linear-gradient(135deg, #9795ba, #6a62d2);
  margin-left: 8px;
}
.userinfo-app-title-conainer {
  display: none;
  align-items: center;
}
.large-userinfo-container {
  margin-right: 20px;
}

.camp-view-button {
  font-size: 13px;
  width: 70px;
  background-image: linear-gradient(to right, #9795ba, #6a62d2);
  margin-left: 8px;
}
.margin-bottom-40 {
  margin-bottom: 40px;
}
.margin-bottom-10 {
  margin-bottom: 10px;
}
.excel-button {
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(to right, #ffe053, #ff5e01);
}
.download-button {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-image: linear-gradient(to right, #9795ba, #6a62d2);

  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  top: 45px;
  right: 45px;
  z-index: 4;
}
.pdf-master-container {
  overflow: auto;
  display: flex;
  justify-content: flex-start;
}
.delete-button {
  font-size: 13px;
  width: 68px;
  background-color: #ff5050;
  margin-left: 8px;
}

.form-delete-button {
  font-size: 13px;
  width: 90px;
  background-color: #ff5050;
  margin-left: 8px;
}
.card-buttons-container {
  display: flex;
  justify-content: flex-end;
  width: 160px;
}
.not-found-text {
  font-size: 14px;
  color: rgb(136, 136, 136);
  margin-right: 10px;
}
.user-details-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  width: 100%;
}
.user-image-cirlce {
  width: 50px;
  height: 50px;
  box-shadow: 0px 6px 8px rgb(185, 185, 185);
  border-radius: 50%;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
}
.c-green {
  color: green !important;
  font-weight: 600 !important;
}
.c-red {
  color: red !important;
  font-weight: 600 !important;
}
.asterisk-icon {
  width: 8px;
  height: 8px;
  margin-right: 5px;
}

.back-button {
  width: 45px;
  height: 45px;
  border-radius: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  margin-right: 10px;
  background-color: white;

  transition: 200ms ease-in-out;
}
.none-text {
  font-size: 13px;
  color: #949494;
  padding-left: 30px;
  margin-bottom: 20px;
}
.camp-selected-item {
  padding: 8px;
  width: 100%;
  font-size: 14px;
  color: #ff5e01;
  text-transform: capitalize;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.camp-selected-list-container {
  list-style-type: disc !important;
  padding-left: 25px;
  margin-bottom: 20px;
}
.remove-button {
  font-size: 12px;
  color: #b1b1b1;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 150ms ease-in-out;
}
.remove-button:hover {
  color: #2f2f2f;
}

.create-button,
.next-button {
  width: 100%;
  background-image: linear-gradient(to right, #9795ba, #6a62d2);
}
.input-container {
  position: relative;
}
.drop-down-option-container {
  margin-top: 10px;
  list-style-type: none;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.19);
  border-radius: 8px;

  position: absolute;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;

  z-index: 3;

  display: none;
  transition: all 0.2s ease-in-out;

  background-color: white;
}
.drop-down-option-button {
  width: 100%;
  border: none;
  outline: none;
  background-color: white;
  font-size: 13px;
  text-align: left;
  padding: 14px 25px;
  border-radius: 8px;
  color: rgb(136, 136, 136);
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.Others-text-container {
  width: 100%;
  border: none;
  outline: none;
  background-color: white;
  font-size: 13px;
  text-align: left;
  padding: 14px 25px;
  border-radius: 8px;
  color: black;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  height: 100px;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.19);
  margin-top: 10px;
  cursor: initial;
}

.search-with-select {
  display: flex;
  align-items: flex-start;
}
.select-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;

  margin-top: 5px;
  border: none;

  width: 100%;
  height: 45px;
  padding: 5px 16px;
  border-radius: 8px;
  box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.19);
  margin-left: 10px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
}

.drop-down-option-button:hover {
  background-color: rgb(223, 223, 223);
  color: black;
}
.cursor-pointer {
  cursor: pointer;
}
.pos-relative {
  position: relative;
}
.text-center {
  text-align: center;
}
.margin-bottom-20 {
  margin-bottom: 20px;
}
.margin-top-30 {
  margin-top: 30px;
}
.font-10 {
  font-size: 10px;
  font-weight: 450;
}
.margin-bottom-40 {
  margin-bottom: 40px;
}
.large-back-button-container {
  display: none;
}
.text-bold {
  font-weight: bold;
}
.apollo-hospitals-text {
  font-size: 12px;
  color: rgb(168, 168, 168);

  position: relative;
  top: 5px;
}
.heading-style-element-container {
  display: flex;
  align-items: center;
  width: 77%;

  position: relative;
  bottom: 5px;
}
.style-element-container {
  display: flex;
  align-items: center;
  flex-grow: 1;

  position: relative;
  bottom: 5px;
}

.style-line {
  width: 100%;
  background-image: linear-gradient(to right, transparent, black);
  height: 2px;
}
.style-dot {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: black;
}
.highlight-content {
  font-size: 14px;
  color: #ff198c;
  font-weight: 800;
}
.highlight-camp-content {
  font-size: 16px;
  color: #ff198c;
  font-weight: 800;
}

/* new participant css */

.bottom-0 {
  bottom: 0px;
}
.rotate-180 {
  transform: rotate(180deg);
}
.margin-left-right-8 {
  margin: 0px 8px;
}
.flex-column {
  flex-direction: column;
}
.gender-button {
  width: 48%;
  background-color: white;
  color: black;
  font-size: 13px;

  transition: 200ms ease-in-out;
}
.gender-button:hover {
  background-color: rgb(201, 201, 201);
}
.gender-button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.date-font-size {
  font-size: 12px;
  color: rgb(131, 131, 131);
}
.padding-0 {
  padding: 0px;
}
.radio-label {
  font-size: 14px;
  margin-left: 13px;
  color: #8a8a8a;

  cursor: pointer;
  transition: 0.2s ease-in-out;
}
.position-relative {
  position: relative;
}
.radio-button {
  z-index: 10;
  opacity: 0;
  cursor: pointer;

  width: 24px;
  height: 24px;
}
.custom-radio {
  position: absolute;
}
.c-radio-button {
  width: 20px;
  height: 20px;
  background-color: white;
  border-radius: 50%;

  box-shadow: 0px 6px 9px rgba(0, 0, 0, 0.19);

  position: absolute;
  left: 0px;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.c-radio-button:after {
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 50%;

  box-shadow: inset 0px 4px 6px rgba(0, 0, 0, 0.19);
}
input:checked ~ .c-radio-button:after {
  background-image: linear-gradient(to right, #ff198c, #ff883e);
  box-shadow: none;
}
.radio-with-lable:hover .radio-label {
  color: #ff883e;
  transform: translateX(-5px);
  font-weight: 600;
}
.margin-bottom-30 {
  margin-bottom: 30px;
}

.margin-auto {
  margin: auto;
}

.margin-auto-left {
  margin-left: auto;
}
.yes-no-button {
  background-color: white;
  color: black;
  width: 70px;
  margin-right: 20px;
  transition: 0.2s ease-in-out;
}
.margin-top-10 {
  margin-top: 10px;
}
.yes-no-button:hover {
  background-color: rgb(201, 201, 201);
}
.button-hightlight-bg {
  color: white;
  background-image: linear-gradient(to right, #ff198c, #ff883e);
}
.max-width-200 {
  max-width: 200px;
}

.border {
  border: 1px solid red;
}
.input-100 {
  width: 100% !important;
}
.copy-rights-text {
  font-size: 12px;
  text-align: center;
  margin-top: 15px;

  color: #5a5a5a;
}
.font-weight-700 {
  font-weight: 700;
}
.end-line {
  margin-top: 40px;
  height: 2px;
  width: 100%;
  background-image: linear-gradient(
    to right,
    transparent,
    #c7c7c7,
    transparent
  );
}
.width-70 {
  width: 70%;
}
.width-40 {
  width: 40%;
}
.width-30 {
  width: 30%;
}
.label-info {
  font-size: 12px;
  color: rgb(131, 131, 131);
  margin-left: 5px;
}
.c-position {
  position: relative;
  left: 20px;
}
.role-content {
  text-transform: capitalize;
}
.disabled-button {
  opacity: 0.5;
  cursor: not-allowed;
}

/* report css */

.report-container {
  width: 100%;
  min-height: 100vh;

  display: flex;
  flex-direction: column;
}
.report-sub-heading {
  font-size: 16px;
  margin-right: 8px;
  margin-bottom: 25px;
}
.tick-mark-container {
  padding: 5px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: rgb(65, 255, 65);

  display: flex;
  justify-content: center;
  align-items: center;
}
.margin-bottom-25 {
  margin-bottom: 25px;
}

.margin-bottom-20 {
  margin-bottom: 25px;
}
.margin-bottom-none {
  margin-bottom: 0px;
}

.margin-right-30 {
  margin-right: 30px;
}
.width-50 {
  width: 50% !important;
}

.participant-info-container {
  margin-bottom: 30px;
}

.info-line-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}
.line-name {
  font-size: 13px;
  width: 30%;
  color: rgb(121, 121, 121);
}
.line-value {
  font-size: 13px;
  width: 65%;
  font-weight: 600;
  text-transform: capitalize !important;
}
.catagory-title {
  font-size: 13px;
  padding: 0px 8px;
}
.catagory-value {
  text-align: center;
  padding: 8px;
  font-size: 14px;
  width: 100px !important;
  background-color: white;
  color: rgb(0, 0, 0);
  border-radius: 8px;
  box-shadow: inset 0px -3px 7px rgba(0, 0, 0, 0.1);
}
.note-heading {
  font-size: 13px;
  font-weight: 600;
}
.note-text {
  font-size: 13px;
  margin-left: 8px;
}
.margin-top-20 {
  margin-top: 20px;
}
.all-risk-catagory-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  position: relative;
  bottom: 15px;
}
.top-modifiable-risk-factors-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.modifiable-risk-factor {
  margin-bottom: 15px;
  width: 150px;
  padding: 12px;
  text-align: center;
  border-radius: 8px;

  font-size: 13px;
  box-shadow: 0px 6px 9px rgba(0, 0, 0, 0.19);
}
.recommended-protocal-sub-heading {
  font-size: 14px;
  color: #3c3c3c;
  font-weight: 600;
}
.recommended-protocal-value {
  font-size: 13px;
  padding-left: 15px;
  margin-top: 8px;
  margin-bottom: 20px;
  color: #3c3c3c;
}

.border-none {
  border: none !important;
}

/* copd form css */

.drugs-list {
  font-size: 12px;
  color: rgb(168, 168, 168);
  margin-bottom: 5px;
}

/* create camp form css */

.margin-top-25 {
  margin-top: 25px;
}

/* report css */

.risk-card-img {
  width: 45px;
}
.risk-card-heading {
  font-size: 16px;
  font-weight: 600;
}
.margin-right-15 {
  margin-right: 15px;
}
.view-more-button {
  width: 100px;
  font-size: 13px;
  background-image: linear-gradient(to right, #ff198c, #ff883e);
}
.justify-content-end {
  justify-content: flex-end;
}

.get-button {
  width: 70px;
  font-size: 13px;
  background-image: linear-gradient(to right, #ff198c, #ff883e);
}
.speciality-content {
  width: 70%;
  font-size: 13px;
}
.align-items-end {
  align-items: flex-end;
}
.margin-top-auto {
  margin-top: 100%;
}
.report-button-container {
  width: 100%;
  max-width: 350px;
  min-width: 200px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  align-self: center;
}
.generate-report-button,
.print-report-button {
  width: 48%;
  background-image: linear-gradient(to right, #ff198c, #ff883e);
  font-size: 13px;
}
.add-new-participant-button {
  width: 100%;
  background-image: linear-gradient(to right, #ff198c, #ff883e);
  font-size: 13px;
  margin-top: 25px;
}

.review-button {
  background-color: #004e11;
  width: 84px;
  margin-left: 8px;
  font-size: 14px;
}

.text-area {
  width: 100%;
  height: 200px;
  padding: 16px;

  outline: none;
  border: none;

  box-shadow: 0px 6px 9px rgba(0, 0, 0, 0.19);
  border-radius: 8px;

  font-size: 14px;
  min-height: 130px;
  margin-bottom: 20px;
}
.date-with-doctor-text-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

/* users-page */
.pop-up-container {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);

  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 5;

  display: flex;
  justify-content: center;
  align-items: center;
}
.pop-up-box {
  width: 80%;
  max-width: 450px;
  height: 350px;
  background-color: white;
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.delete-container {
  width: 80%;
  max-width: 400px;
  height: 200px;
  background-color: white;
  padding: 30px 25px;
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.d-none {
  display: none;
}
.text-capitalize {
  text-transform: capitalize;
}
.delete-text {
  font-size: 18px;
  font-weight: 600;
  margin-top: 15px;
}
.confirm-text {
  color: rgb(255, 82, 82);
  font-weight: 700;
}
.cancel-button {
  width: 100px;
  background-color: #ff883e;
  margin-right: 15px;
}
.success-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
}
.tickmark-container {
  width: 50px;
  height: 50px;
  background-color: rgb(49, 255, 59);
  padding: 8px;
  border-radius: 50%;
  margin-bottom: 25px;
}
.close-container {
  width: 50px;
  height: 50px;
  background-color: rgb(255, 79, 62);
  padding: 8px;
  border-radius: 50%;
  margin-bottom: 25px;
}
.done-button {
  margin-top: 30px;
  width: 100px;
  background-image: linear-gradient(to right, #9795ba, #6a62d2);
}
.create-new-button {
  width: 49%;
  background-image: linear-gradient(to right, #9795ba, #6a62d2);
}
.success-text {
  font-size: 18px;
  text-align: center;
}
.confirm-button {
  width: 100px;
  background-color: rgb(255, 82, 82);
}
.delete-button-container {
  display: flex;
  justify-content: flex-end;
}

.common-report-loader-container {
  height: 90vh;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

/* pdf style */

.pdf-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.page {
  width: 210mm;
  /* height: 295mm; */
  position: relative;
  display: flex;
  flex-direction: column;
  /* overflow-y: auto; */
}
.page-header {
  width: 100%;
  height: 90px;
  background-color: #17748f;

  display: flex;
  justify-content: space-between;

  align-items: flex-end;
  padding: 15px 25px;
}
.header-heading {
  font-size: 24px;
  color: white;
  font-family: "Roboto", sans-serif;
}
.header-log {
  width: 80px;
}
.pdf-report-custom-button {
  position: fixed;
  top: 90%;
  left: 90%;
  z-index: 10;
  width: 100px !important;
}
.margin-bottom-8 {
  margin-bottom: 8px;
}

.pdf-content-container {
  padding: 10px 25px;
}
.pdf-user-info-container {
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: space-between;
  flex-wrap: wrap;
  height: 55px;
  padding: 5px 15px;

  border-left: 5px solid #f2b12a;
  margin-bottom: 15px;
}
.pdf-user-info-container li {
  width: 33%;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  margin: 0;
  padding: 0%;
}
.pdf-user-info-heading {
  font-size: 14px;
  font-weight: 600;
  width: 70px;
  font-family: "Roboto", sans-serif;
  margin: 0px;
}
.pdf-user-info-value {
  font-size: 14px;
  margin: 0px;
  margin-left: 8px;
  color: #17748f;
  text-transform: capitalize;
  font-family: "Roboto", sans-serif;
}
.line {
  width: 100%;
  height: 1px;
  background-color: rgb(156, 156, 156);
  margin-bottom: 15px;
}
.pdf-sub-heading {
  font-size: 14px;
  margin-bottom: 8px;
  color: #17748f;
  font-family: "Roboto", sans-serif;
}
.pdf-page-text {
  font-size: 13px;
  line-height: 18px;
  margin-bottom: 15px;
  font-family: "Roboto", sans-serif;
}
.pdf-camp-detials-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}
.pdf-each-camp-details-container {
  display: flex;
  align-items: center;
}
.pdf-each-camp-details-container p {
  font-size: 13px;
  font-family: "Roboto", sans-serif;
}
.pdf-camp-details-var-container {
  border: 1px solid rgb(104, 104, 104);
  padding: 5px 8px;
  height: 30px;
  border-radius: 4px;
  flex-grow: 1;
  margin-left: 8px;
  text-transform: capitalize;
}
.width-48 {
  width: 48%;
}
.width-30 {
  width: 30%;
}
.width-20 {
  width: 20%;
}
.history-info-container {
  width: 48%;
  border: 1px solid #ff4b23;
  border-radius: 8px;
}
.pdf-box-heading {
  width: 250px;
  background-color: #ff4b23;
  color: white;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  padding: 10px 14px;
  border-top-left-radius: 5px;
  border-bottom-right-radius: 10px;
  margin-bottom: 6px;
}
.pdf-check-mark {
  width: 15px;
}
.tik-container {
  width: 20px;
  height: 20px;
  border: 1px solid #ff4b23;

  border-radius: 3px;
  margin-left: 6px;

  display: flex;
  justify-content: center;
  align-items: center;
}
.red-solid-bg {
  background-color: #ff4b23;
}
.yes-no-container {
  display: flex;
  align-items: center;
}
.yes-no-container p {
  font-size: 13px;
  font-family: "Roboto", sans-serif;
}
.history-each-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 10px;
  border-bottom: 1px solid #df4b2b;
}

.ml-10 {
  margin-left: 10px;
}
.history-info-heading {
  font-size: 13px;
  font-family: "Roboto", sans-serif;
  margin-left: 30px;
}
.history-info-main-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 15px;
}

.filled-values-container {
  display: flex;
  flex-wrap: wrap;
}
.covid-info-container {
  border: 1px solid #17748f;
  border-radius: 8px;
  margin-bottom: 15px;
}
.blue-solid-bg {
  background-color: #17748f;
}
.covid-heading {
  width: 250px;
  background-color: #17748f;
}
.covid-border {
  border: 1px solid #17748f;
}
.field-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 10px;
}
.text-capital {
  text-transform: capitalize;
}
.large-userinfo-container {
  display: flex;
  align-items: center;
}
.large-userinfo {
  margin-right: 10px;
}
.user-name {
  text-transform: capitalize;
  font-size: 14px;
}
.larg-user-name {
  font-size: 14px;
  text-transform: capitalize;
  font-weight: 600;
  text-align: right;
}
.user-role {
  text-transform: capitalize;
  font-size: 12px;
}
.large-user-role {
  font-size: 11px;
  text-transform: capitalize;
  color: #ff4b23;
  font-weight: 600;
  text-align: right;
}
.field-text {
  font-size: 13px;
  font-family: "Roboto", sans-serif;
}
.three-field-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.date-container {
  width: 100px;
  min-height: 23px;
  border: 1px solid #17748f;
  border-radius: 3px;
  display: flex;
  align-items: center;
  padding: 2px 8px;
  font-size: 13px;
  text-transform: capitalize;
  font-family: "Roboto", sans-serif;
}
.width-33 {
  width: 33%;
}
.life-style-bg {
  background-color: #167c69;
}
.lifestyle-bg {
  border: 1px solid #167c69;
}

.section-info-container {
  border: 1px solid;
  border-radius: 8px;
  margin-bottom: 20px;
}

.pdf-box-heading {
  padding: 10px;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  margin: 0;
}

.three-column-grid {
  display: flex;
  flex-wrap: wrap;
}

.field-container {
  width: 33.33%;
  box-sizing: border-box;
  padding: 10px;
}

.field-text {
  font-weight: bold;
}

.date-container {
  padding: 5px;
  border: 1px solid;
  border-radius: 4px;
}

.width-100 {
  width: 100%;
}

.field-text {
  font-weight: bold;
}

.date-container {
  padding: 5px;
  border: 1px solid;
  border-radius: 4px;
}
.justify-content-start {
  justify-content: flex-start !important;
}
.checkbox-input {
  height: 24px;
  width: 24px;
  cursor: pointer;
  opacity: 0;
}
.custom-checkbox {
  width: 24px;
  height: 24px;
  border-radius: 5px;
  border: 2px solid white;
  box-shadow: 2px 2px 5px rgb(177, 177, 177),
    inset 2px 2px 3px rgb(177, 177, 177);
  pointer-events: none;
  background-image: linear-gradient(white);

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1px;

  position: absolute;
}
.custom-checkbox_border_radius {
  border-radius: 50%;
}
.checkbox-input:checked ~ .custom-checkbox {
  background-image: linear-gradient(135deg, #6a62d2, #6a62d2);
  box-shadow: 2px 2px 5px rgb(177, 177, 177);
}
.col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 33%;
}
.width-100 {
  width: 100%;
}
.footer-text {
  font-size: 11px;
  font-family: "Roboto", sans-serif;
}
.footer-container {
  margin-top: auto;
}
.line-1 {
  background-color: #17748f;
  height: inherit;
  width: 60%;
}
.line-2 {
  background-color: #f2b12a;
  height: inherit;
  width: 40%;
}
.d-flex {
  display: flex;
}
.flex-row {
  flex-direction: row;
}
.flex-column {
  flex-direction: column;
}
.justify-space-between {
  justify-content: space-between;
}
.style-line-container {
  margin-top: 6px;

  display: flex;
  align-items: center;
  width: 100%;
  height: 8px;
}
.plr-25 {
  padding: 0px 25px;
}
.alingn-item-end {
  align-items: flex-end;
}
.mb-4 {
  margin-bottom: 4px;
}
.second-page-container {
  width: 100%;
  border: 1px solid #4bab4a;
  border-radius: 8px;
  margin-bottom: 15px;
}
.life-style-bg {
  background-color: #167c69;
}
.lifestyle-bg {
  border: 1px solid #167c69;
}

.lifestyle-info-container {
  border: 1px solid #167c69;
  border-radius: 8px;
}

.show-more-button-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
}
.show-more {
  background-image: linear-gradient(135deg, #ff198c, #ff883e);
  width: 100px;
  font-size: 13px;
}

.forget-text {
  font-size: 13px;
  color: rgb(255, 55, 105);
  text-align: right;
  text-decoration: none;
}
.high-risk {
  background-color: rgb(255, 83, 83) !important;
  color: rgb(255, 255, 255) !important;
  font-weight: 600;
}
.medium-risk {
  background-color: rgb(255, 211, 13) !important;
  color: rgb(34, 33, 33) !important;
  font-weight: 600;
}
.low-risk {
  background-color: rgb(94, 255, 88) !important;
  color: rgb(34, 33, 33) !important;
  font-weight: 600;
}
.branding-main-text {
  font-size: 16px;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
}
.branding-sub-text {
  font-size: 12px;
  position: relative;
  top: 5px;
  font-family: "Roboto", sans-serif;
}
.justify-content-space-between {
  justify-content: space-between !important;
}
.mb-50 {
  margin-bottom: 50px !important;
}
.p-10 {
  padding: 10px;
}
.font-bold {
  font-weight: 600;
}
.each-var-container {
  border: 1px solid #4bab4a;
  width: 60%;
  min-height: 23px;
  border-radius: 3px;
  margin-left: 8px;
  padding: 4px 8px;
  font-size: 13px;
  font-family: "Roboto", sans-serif;
}
.each-container {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
.inside-container {
  padding: 6px 12px;
}
.each-container-text {
  font-size: 13px;
  width: 120px;
  font-family: "Roboto", sans-serif;
}
.width-33 {
  width: 33%;
}
.width-25 {
  width: 25%;
}
.risk-text {
  font-size: 13px;
  margin-left: 12px;
  font-family: "Roboto", sans-serif;
}
.aicvd-note-text {
  font-size: 11px;
  font-family: "Roboto", sans-serif;
  background-color: #3c3b3a;
  color: white;
  padding: 4px 12px;
  border-bottom-right-radius: 7px;
  border-bottom-left-radius: 7px;
  margin-top: 6px;
}
.width-85 {
  width: 85%;
}
.diabetes-container {
  background-color: #1f609e;
  border: 1px solid #1f609e;
  padding: 8px 20px;
}
.text-white {
  color: white;
}
.diabetes-var-container {
  background-color: white;
  min-height: 23px;
  padding: 4px 8px;
  font-size: 13px;
  font-family: "Roboto", sans-serif;
  margin-top: 6px;
  border-radius: 3px;
}
.diabetes-text {
  font-size: 11px;
  margin-top: 12px;
  color: white;
  font-family: "Roboto", sans-serif;
}
.certificate-text {
  font-size: 13px;
  text-align: center;
  color: #d14247;
  margin-bottom: 15px;
  font-family: "Roboto", sans-serif;
}
.aicvd-recommendations {
  background-color: #f1b02b;
  color: black;
}
.diabetes-recommendations {
  background-color: #70933f;
}
.aicvd-recommendations-border {
  border: 1px solid #f1b02b;
}
.diabetes-recommendations-border {
  border: 1px solid #70933f;
}
.aicvd-recommendation-list-container {
  list-style-type: none;

  display: flex;
  flex-direction: column;
}
.aicvd-recommendation-list-item {
  display: flex;
  border-bottom: 1px solid #f1b02b;
}
.list-item-heading {
  width: 20%;
  border-right: 1px solid #f1b02b;
  font-size: 13px;
  font-family: "Roboto", sans-serif;
  padding: 6px 12px;
}
.diabetes-list-right-border {
  border-right: 1px solid #70933f;
}
.diabetes-list-bottom-border {
  border-bottom: 1px solid #70933f;
}
.list-item-value {
  width: 80%;
  font-size: 13px;
  padding: 6px 12px;
  font-family: "Roboto", sans-serif;
}
.border-none {
  border: none;
}
.review-heading {
  font-size: 13px;
  width: 15%;
  font-family: "Roboto", sans-serif;
}
.mb-20 {
  margin-bottom: 20px;
}
.mt-20 {
  margin-top: 20px;
}
.review-container {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
}
.review-value {
  font-size: 13px;
  width: 85%;
  line-height: 20px;
  text-transform: capitalize;
  font-family: "Roboto", sans-serif;
}
.mt-70 {
  margin-top: 70px;
}
.disclaimer-list-conatiner {
  list-style-type: number;
  padding-left: 30px;
}
.disclaimer-list-item {
  font-size: 13px;
  margin-bottom: 20px;
  font-family: "Roboto", sans-serif;
}
.logo-container {
  width: 200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: center;
  margin-top: 50px;
}
.branding-logo {
  width: 90px;
}
.width-90 {
  width: 90%;
}
.diabetes-note {
  font-size: 13px;
  color: red;
  text-align: center;
  margin-bottom: 35px;
}
.width-60 {
  width: 60%;
}

/* 
* {
  border: 0.5px solid rgb(209, 209, 209);
} */

/* edit-user-form */
.edit-user-form {
  width: 85%;
  max-width: 450px;
  background-color: white;
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 45px 25px;
}
.edit-form-heading {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 35px;
  text-align: center;
}
.change-password-button {
  width: 49%;
  margin-top: 10px;
  background-image: linear-gradient(135deg, #ff198c, #ff883e);
}
.edit-button-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.camp-heading {
  display: none;
}
.text-capital {
  text-transform: capitalize !important;
  font-family: "Roboto", sans-serif;
}
.pt-20 {
  padding-top: 20px;
}
.pl-15 {
  padding-left: 15px;
}
.custom-lab-test-container {
  width: 100%;
  padding: 0px 10px;

  margin-bottom: 20px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  list-style-type: none;
}

.custom-lab-label {
  font-size: 14px;
  margin-bottom: 15px;
  color: #000000;
  font-weight: 600;
}

.align-items-start {
  align-items: flex-start;
}
.mb-25 {
  margin-bottom: 25px;
}
.custom-patient-report-test-container {
  list-style-type: none;
  padding-left: 25px;
  margin-bottom: 20px;
}
.report-test-item {
  position: relative;
}
.camp-label-check-box-container {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  margin-top: 15px;
}
.camp-checkbox-label {
  font-size: 14px;
  padding-left: 10px;
  cursor: pointer;
  user-select: none;
}
.label-sub-text {
  font-size: 12px;
  color: #8b8b8b;
}

.checkbox {
  margin-right: 7px;
  position: relative;
  width: 25px;
  height: 25px;
  opacity: 0;
  z-index: 4;
  cursor: pointer;
}
.c-checkbox {
  width: 25px;
  height: 25px;
  border: 2px solid white;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2),
    inset 0px 3px 3px rgba(0, 0, 0, 0.2);

  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 0px;
  pointer-events: none;
  cursor: pointer !important;

  border-radius: 5px;
}
.checkbox:checked ~ .c-checkbox {
  background-image: linear-gradient(135deg, #ff198c, #ff883e);
}
.report-test-item {
  display: flex;
  align-items: center;

  margin-bottom: 15px;
}
.checkbox-label {
  font-size: 14px;
  margin-left: 3px;
  color: #5a5a5a;
  cursor: pointer;
}
.review-risk-status-box {
  padding: 3px 10px;
  border-radius: 5px;
}
.dark-placeholder::placeholder {
  color: rgb(155, 155, 155);
}
.mt-auto {
  margin-top: auto !important;
}
.test-add-button {
  width: 50px;
  height: 90%;
  background-image: linear-gradient(135deg, #9795ba, #6a62d2);
  border: none;
  outline: none;
  border-radius: 5px;

  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  transition: all 200ms ease-in-out;
}
.test-add-button:hover {
  filter: hue-rotate(90deg);
}
.sub-text {
  font-size: 13px;
  color: rgb(128, 128, 128);
}
.min-width-160 {
  min-width: 160px;
}
.p-e-cursor {
  pointer-events: stroke !important;
}
.diabetes-note-text {
  font-size: 13px;
  margin-bottom: 25px;
  color: rgb(255, 113, 113);
  text-align: center;
}
.diabetes-note-title {
  font-weight: 600;
}
.popup-main-container {
  width: 100%;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0px;
  left: 0px;
  padding: 35px;

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}
.warring-popup-container {
  min-width: 250px;
  max-width: 600px;
  min-height: 220px;

  border-radius: 8px;
  padding: 30px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: white;
}
.warring-popup-content-heading {
  font-size: 20px;
  color: red;
  text-align: center;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  user-select: none;
}
.warning-icon {
  width: 30px;
}
.popup-text-highlight-text {
  font-size: 14px;
  text-align: center;
  user-select: none;
}
.warring-button {
  background-image: linear-gradient(135deg, #ff198c, #ff883e);
  color: rgb(255, 255, 255);
  font-weight: 600;
  margin-top: 30px;
  padding: 8px 24px;
}
.main-loader-container {
  width: 100%;
  height: 300px;

  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.loader-spinner {
  width: 40px;
  height: 40px;
  border: 2px solid rgb(87, 87, 87);
  border-radius: 50%;
  border-left: none;
  border-bottom: none;
  animation: spin 700ms linear infinite;
  margin-bottom: 15px;
}
.no-increment::-webkit-outer-spin-button,
.no-increment::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (min-width: 400px) {
  .login-form-container {
    margin-top: 45px;
  }
  .login-logo {
    width: 110px;
    align-self: flex-end;
  }
}

@media screen and (min-width: 450px) {
  .card-container {
    justify-content: flex-start;
  }
  .card {
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .sub-heading {
    font-size: 20px;
    margin-right: 10px;
  }
  .add-button {
    padding: 0px 20px;
    background-image: linear-gradient(135deg, #9795ba, #6a62d2);
    font-size: 14px;
  }
  .all-risk-catagory-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    color: rgb(36, 34, 34);
  }
  .risk-catagory-container {
    margin-right: 15px;
  }
}

@media screen and (min-width: 768px) {
  .userinfo-app-title-conainer {
    display: flex;
  }
  .p-l-20 {
    padding: 0px 20px;
  }
  .custom-lab-label {
    font-size: 16px;
  }
  .edit-user-form {
    padding: 40px 55px;
  }
  .camp-heading {
    display: block;
  }
  .navigation-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100px;
    padding: 25px 15px;
    box-shadow: 6px 0px 8px rgba(0, 0, 0, 0.19);
    z-index: 1;
  }
  .content-container {
    width: 100%;
    padding: 35px;
  }
  .large-back-button-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 30px;
  }
  .large-back-button-text {
    font-size: 16px;
    font-weight: 600;
    margin-left: 10px;
    transition: 300ms ease-in-out;
    user-select: none;
  }
  .large-back-button-container:hover .large-back-button-text {
    transform: translateX(-8px);
    color: rgb(255, 73, 73);
  }
  .generate-report-button,
  .print-report-button {
    font-size: 14px;
  }

  .card {
    width: 200px;
    height: 130px;
    margin-right: 15px;
    margin-bottom: 15px;
  }
  .menu-icon-with-apollo-logo {
    display: none;
  }
  .card-text {
    font-size: 14px;
  }
  .card-value {
    font-size: 24px;
  }
  .heading {
    font-size: 30px;
  }
  .heading-with-icon-container {
    margin-bottom: 40px;
  }
  .about-heading-text {
    font-size: 14px;

    position: relative;
    bottom: 20px;
  }
  .sub-heading {
    font-size: 22px;
    margin-right: 10px;
  }
  .add-button {
    padding: 0px 25px;
    background-image: linear-gradient(135deg, #9795ba, #6a62d2);
    font-size: 14px;
  }
  .sub-heading-icon {
    width: 30px;
  }
  .sub-heading-with-button-container {
    margin-bottom: 45px;
  }
  .title {
    font-size: 12px;
    color: #626262;
  }
  .content {
    font-size: 14px;
    font-weight: 600;
    text-transform: capitalize;
  }
  .not-found-text {
    font-size: 16px;
  }
  .create-button,
  .next-button {
    width: 250px;
    align-self: center;
  }
  .large-d-m-b-25 {
    margin-bottom: 20px;
  }
  .user-create-input {
    width: 390px;
  }
  .start-end-date-container {
    width: 45%;
  }

  .camp-category-container {
    width: 50%;
  }
  .login-logo {
    position: absolute;
    top: 50px;
    right: 50px;
  }
  .login-form-container {
    width: 60%;
    align-self: center;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.19);
    padding: 40px 30px;
    border-radius: 15px;
  }
  .small-screen {
    display: none;
  }
  .login-container {
    justify-content: center;
    align-items: center;
  }
  .large-screen {
    display: block;
    margin-top: 45px;
  }
  .apollo-hospitals-text {
    font-size: 13px;
  }
  .highlight-camp-content {
    font-size: 16px;
    color: #ff198c;
    font-weight: 800;
  }
  .gender-button {
    font-size: 14px;
  }
  .date-font-size {
    font-size: 14px;
  }
  .radio-label {
    font-size: 16px;
    margin-left: 14px;
  }
  .radio-button {
    width: 24px;
    height: 24px;
  }
  .c-radio-button {
    width: 24px;
    height: 24px;
    left: 0;
  }
  .c-radio-button:after {
    width: 16px;
    height: 16px;
  }
  .drugs-list {
    font-size: 13px;
    margin-bottom: 8px;
  }
  .note-heading {
    font-size: 14px;
    font-weight: 600;
  }
  .note-text {
    font-size: 14px;
    margin-left: 8px;
  }
  .catagory-value {
    width: 130px;
    padding: 16px;
  }
  .line-value,
  .line-name {
    font-size: 14px;
    text-transform: capitalize !important;
  }
  .top-modifiable-risk-factors-container {
    justify-content: flex-start;
  }
  .modifiable-risk-factor {
    width: 190px;
    margin-left: 20px;
    margin-bottom: 20px;
    font-size: 14px;
  }
}

@media screen and (min-width: 992px) {
  .navigation-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100px;
    padding: 0px;
    box-shadow: 6px 0px 8px rgba(0, 0, 0, 0.19);
    z-index: 1;
  }
  .content-container {
    width: 100%;
    padding: 45px;
  }
  .menu-icon-with-apollo-logo {
    display: none;
  }
  .card {
    width: 240px;
    height: 130px;
    margin-right: 15px;
    margin-bottom: 15px;
  }
  .nav-option-text {
    font-size: 11px;
  }
  .button-text {
    font-size: 16px;
  }
  .about-heading-text {
    font-size: 16px;

    position: relative;
    bottom: 25px;
  }
  .add-button {
    padding: 0px 30px;
    background-image: linear-gradient(135deg, #9795ba, #6a62d2);
    font-size: 16px;
  }
  .title {
    font-size: 13px;
    color: #626262;
  }
  .content {
    font-size: 14px;
    font-weight: 600;
    text-transform: capitalize;
  }

  .form-card-buttons-container {
    display: flex;
    justify-content: flex-end;
    width: 200px;
  }
  .card-buttons-container {
    display: flex;
    justify-content: flex-end;
    width: 160px;
  }
  .edit-button,
  .camp-view-button,
  .form-edit-button {
    font-size: 14px;
    width: 75px;
    margin-left: 8px;
  }
  .delete-button,
  .form-delete-button {
    font-size: 14px;
    width: 75px;
    background-color: #ff4f4f;
    margin-left: 10px;
  }
  .drop-down-option-button {
    font-size: 14px;
  }
  .user-create-input {
    width: 350px;
  }
  .login-form-container {
    width: 30%;
    padding: 50px 40px;
  }
  .highlight-camp-content {
    font-size: 16px;
    color: #ff198c;
    font-weight: 800;
  }
}

@media screen and (min-width: 1200px) {
  .navigation-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 120px;
    padding: 25px 15px;
  }
  .content-container {
    width: 100%;
  }
  .details-cards-container {
    justify-content: space-between;
  }
  .details-card-container {
    max-width: 330px;
  }
  .card-content-gap {
    margin-bottom: 18px;
  }
  .not-found-text {
    font-size: 18px;
  }
  .user-create-input {
    width: 400px;
  }
  .apollo-hospitals-text {
    font-size: 14px;
  }
  .participant-info-container {
    width: 100%;
    padding: 10px;
    border-radius: 8px;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
  }

  .info-line-container {
    width: 50%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
  }
  .line-name,
  .line-value {
    font-size: 16px;
  }
}

/* width */
::-webkit-scrollbar {
  height: 4px;
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #dfdfdf;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(126, 126, 126);
  border-radius: 50px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(97, 97, 97);
}

.terms-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
}

.title-terms-button {
  background-color: #2196f3;
  color: #fff;
  font-size: 16px;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: red;
}
