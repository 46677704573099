.checkbox-option {
  display: flex;
  align-items: center;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 10px;
  margin-top: 10px;
  background-color: white;
}

.drag-handle {
  cursor: grab;
}

.box-input-element {
  margin-right: 10px;
}
.text-input-element {
  margin-right: 10px;
  width: 100%;
  border: none;
  outline: none;
  padding: 10px;
  font-size: 16px;
  border-bottom: 1px solid #ddd;
}

/* .text-input-element:hover {
  border-bottom: 3px solid #6a62d2;
} */

.add-btn,
.remove-btn {
  background: none;

  border: none;
  color: #6a62d2;
  cursor: pointer;
  font-size: 16px;
  margin-left: 5px;
}

.add-btn:hover,
.remove-btn:hover {
  color: #6a62d2;
}

.option-operation-icons {
  width: 24px;
  height: 24px;
}

.checkbox-button {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 30px;
  height: 24px;
  background-color: #fff;
  border: 2px solid #ccc;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  position: relative;
  box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.2),
    inset 0px 2px 2px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  transition: background-color 0.3s, border-color 0.3s, box-shadow 0.3s;
}

.checkbox-button:checked {
  background-color: #6a62d2;
}

.radio-button {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 30px;
  height: 25px;
  background-color: #fff;
  border: 2px solid #ccc;
  border-radius: 50%; /* Make it round for the radio button */
  outline: none;
  cursor: pointer;
  position: relative;
  box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.2),
    inset 0px 2px 2px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s, border-color 0.3s, box-shadow 0.3s;
}

.radio-button::before {
  content: ""; /* Empty content */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 25px;
  height: 25px;
  border-radius: 50%; /* Create a small circle */
  background-color: transparent; /* Hidden circle initially */
  transition: background-color 0.3s;
}

/* Show circle when radio button is checked */
.radio-button:checked::before {
  background-color: #6a62d2; /* Color of the circle when checked */
}
