.review-page-container {
  width: 100%;
  height: 100%;
  background-color: #e9f0f0;
  padding: 20px;
}

.block-1,
.block-2,
.block-3,
.block-4,
.block-5 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.patient-details-block {
  display: flex;
  flex-direction: column;
  width: 25%;
  height: 300px;
  background-color: white;
  padding: 20px;
  border-radius: 20px;
}

.patient-vitals-block {
  width: 72%;
  height: 300px;
  background-color: white;
  border-radius: 20px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.profile-image-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.doctor-review-profile-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: lightgray;
  margin-bottom: 10px;
}

.doctor-review-patient-name {
  font-size: 16px;
  color: #00545e;
  font-weight: bold;
  margin-bottom: 5px;
}

.patient-age-gender-container {
  display: flex;
  margin-bottom: 10px;
}

.doctor-review-patient-age,
.doctor-review-patient-gender {
  font-size: 12px;
  color: #00545e;
  font-weight: 400;
}

.patient-info-block {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 25px;
}

.each-field-patient-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #00545e;
}

.each-field-patient-info-title {
  font-size: 14px;
  font-weight: 400;
}

.each-field-patient-info-value {
  text-align: left;
  font-size: 14px;
  font-weight: 600;
}

.vitals-title {
  font-size: 16px;
  color: #ef6b4e;
  margin-bottom: 20px;
  font-weight: bold;
}

.vitals-blocks {
  display: flex;
  justify-content: space-between;
}

.vital-block {
  border-radius: 10px;
  padding: 10px;
  width: 23%;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 -4px 8px rgba(0, 0, 0, 0.1),
    4px 0 8px rgba(0, 0, 0, 0.1), -4px 0 8px rgba(0, 0, 0, 0.1);
  height: 200px;
  color: #00545e;
  overflow: hidden;
}

.vital-image-title-container {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.vital-image-container {
  margin-right: 10px;
  padding: 10px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.heart-rate-image {
  background-color: #d2b6b6;
}

.sbp-image {
  background-color: #c7e4e8;
}

.dbp-image {
  background-color: #f4e4cc;
}

.spo2-image {
  background-color: #c1e4cf;
}

.each-vital-image {
  width: 20px;
  height: 20px;
}

.vital-name {
  font-size: 14px;
}

.vitals-value-container {
  display: flex;
  flex-direction: column;
}

.vital-value {
  font-size: 16px;
  font-weight: bold;
  color: #00545e;
}

.vital-value-status {
  font-size: 14px;
  color: #00545e;
  font-weight: 300;
}

.vitals-graph {
  width: 100%;
  height: 60px; /* Adjust the height to fit within the card */
  background: none;
  border-radius: 10px;
  position: absolute;
  left: 0;
  right: 0;
}

.canvasjs-chart-credit {
  display: none !important;
}

.patient-complete-details-block {
  width: 100%;
  height: 350px;
  border-radius: 20px;
  background-color: white;
  padding: 20px;
}

.statistics-block,
.doctor-report-block {
  width: 49%;
  height: 280px;
  border-radius: 20px;
  background-color: white;
}

.attributes-header {
  display: flex;
}

.attribute-select-container {
  position: relative;
  /* width: 100%; */
  max-width: 300px;
  margin-left: 10px;
}

.atrribute-select-tag {
  width: 100px;
  padding: 5px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 -4px 8px rgba(0, 0, 0, 0.1),
    4px 0 8px rgba(0, 0, 0, 0.1), -4px 0 8px rgba(0, 0, 0, 0.1);
  font-size: 12px;
  background-size: 12px;
  outline: none;
  border: none;
}

@media screen and (min-width: 300px) and (max-width: 1024px) {
  .block-1,
  .block-2,
  .block-3,
  .block-4,
  .block-5 {
    flex-direction: column;
  }

  .patient-details-block,
  .patient-vitals-block {
    width: 100%;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 600px) {
  .vitals-blocks {
    flex-direction: column;
  }

  .vital-block {
    width: 100%;
    margin-bottom: 10px;
  }

  .patient-vitals-block {
    height: fit-content;
  }
  .catagory-value {
    width: auto !important;
  }
  .risk-catagory-container {
    margin-bottom: 10px;
  }
}
