.input-field-container {
  margin-bottom: 10px;
  display: flex;
  border-radius: 5px;
  flex-direction: column;
}

.input-field:hover {
  border-bottom: 3px solid #6a62d2;
}

.input-field {
  width: 95%;
  border: none;
  outline: none;
  padding: 10px;
  font-size: 14px;
  border-bottom: 1px solid #ddd;
}

.input-field::placeholder {
  color: #aaa;
}

.input-field-container .icon {
  margin-right: 10px;
  color: #ccc;
}

.disabled-input-field {
  width: 80%;
  border: 1px solid lightgrey;
  outline: none;
  margin-top: 20px;
  padding: 10px;
  border-radius: 10px;
}

.long-text-disabled-input {
  width: 80%;
  border: 1px solid lightgrey;
  outline: none;
  margin-top: 20px;
  border-radius: 10px;
  height: 100px;
  padding: 10px;
}

.short-text-disabled-input {
  width: 80%;
  border: 1px solid lightgrey;
  outline: none;
  margin-top: 20px;
  border-radius: 10px;
  height: 50px;
  padding: 10px;
}

.upload-component-disabled-input {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  border: 2px dashed lightgrey;
  outline: none;
  margin-top: 20px;
  border-radius: 10px;
  height: 100px;
  color:#aaa
}

.placeholder-upload-image {
  margin-right:10px;
}
