.minimap-container {
  position: fixed;
  right: 20px;
  top: 80px;
  width: 150px;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  height: calc(100vh - 60px);
  box-shadow:  4px 0 4px rgb(0 0 0 / 10%), -4px 0 4px rgb(0 0 0 / 10%);

}

.minimap-container::-webkit-scrollbar {
  display: none;
}

.minimap-list {
  list-style-type: none;
  padding: 0;
}

.minimap-list li {
  cursor: pointer;
  margin-bottom: 5px;
  font-size: 14px;
}

.minimap-list li:hover {
  text-decoration: underline;
}

.minimap-list ul {
  list-style-type: none;
  padding-left: 15px;
}
