.yes-no-option .button-group {
  display: flex;
  width: 100%;
}

.yes-no-option .button {
  padding: 10px 20px;
  margin: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: white;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 40%;
  color: black;
}

.yes-no-option .button.selected {
  background-color: #6a62d2;
  color: white;
  border-color: #6a62d2;
}
