.each-element {
  display: flex;
  align-items: center;
  padding: 15px 0;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.each-element:hover {
  transform: scale(1.02);
  box-shadow: 0px 0px 10px rgb(0 0 0 / 25%);
  padding-left: 5px;
  border-radius: 10px;
  cursor: pointer;
  transition: all 150ms ease-in-out;
}

.form-element-icon {
  margin-right: 10px;
  font-size: 20px; /* Adjust the size of the icons */
  color: #333;
}

.form-element-title {
  font-size: 16px;
  color: #333;
}
