
  .content-header-text{
    font-size: 16px;
    color:black
  }

  .d-flex{
    display:flex;
  }
  .flex-column{
    flex-direction: column;
  }

  .m-auto{
    margin:auto;
  }

  .font-14{
    font-size:14px;
    font-weight: 300;
    font-family: 'Courier New', Courier, monospace;
  }

  .consent-sub-heading{
    font-size: 14px;
    color:black;
    font-weight: 600;
  }

  .background-color{
    background-color: #f2f2f2;

  }
  
  .margin-left{
    margin-left:20px;
  }

  ol.list-of-instructions li{
    margin-bottom: 10px;
  }

  div.body-instructions p {
    margin-bottom: 10px;
  }
  
  .margin-left-10{
    margin-left:10px;

  }

  .margin-bottom-5{
    margin-bottom:5px;
  }

  .mb-10{
    margin-bottom:10px;
  }

  .mb-30{
    margin-bottom:30px;
  }