.form-fields-page-container {
  min-height: 600px;
  box-shadow: 0 4px 6px 2px #bcbacf;
  border-radius: 20px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.inside-fields-form-container {
  padding-left: 20px;
}

.fields-element-container {
  border: 1px solid red;
  padding: 20px;
  flex-grow: 1;
}

.each-form-field {
  width: 100%;
}

.section-form-fields-container {
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  gap:40px;
  padding: 10px;
  width: 100%;
}

.section-form-fields {
  display: flex;
  flex-direction: column;
  margin: 0.5em;
  text-transform: capitalize;
  /* flex: 1 1 calc(250px - 50px); Adjust to minmax size minus gap */
  min-width: calc(150px - 50px); 
  max-width: 100%;
}

.field-label {
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
}

.form-submit-button {
  background: #6a62d2;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  margin-top:10px;
}

.form-section-name-title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #6a62d2;
}

button[type="submit"]:hover {
  background: #6a62d2;
}

.submit-conatiner {
  display: flex;
  justify-content: center;
  align-items: center;
}

.title-container {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #6a62d2;
}

.field-checkbox-group {
  display: flex;
  margin-bottom: 10px;
}

.checkbox {
  margin-right: 7px;
  position: relative;
  width: 20px;
  height: 20px;
  opacity: 1;
}
.c-checkbox {
  width: 22px;
  height: 22px;
  background-color: white;
  border: 2px solid white;
  box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.2),
    inset 0px 2px 2px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  position: absolute;
  pointer-events: none;

  display: flex;
  justify-content: center;
  align-items: center;
}

.checkbox:checked ~ .c-checkbox {
  background-color: #6a62d2;
}

.cursor-pointer {
  cursor: pointer;
}
.check-icon {
  width: 90%;
}
.checkbox-label {
  font-size: 14px;
  margin-left: 10px;
  user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.radio-button {
  width: 20px;
  height: 20px;
  opacity: 0;
}
.c-radio {
  width: 25px;
  height: 25px;
  border: 2px solid white;
  box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.2),
    inset 0px 2px 2px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  pointer-events: none;
  position: relative;
  right: 20px;
}

input:checked ~ .c-radio {
  background-color: #6a62d2;
  border: 2px solid rgb(255, 255, 255);
}

.radio-label-container {
  margin-right: 20px;
  display: flex;
  margin-bottom: 20px;
}

.option-list-info {
  margin-top: 10px;
  margin-left: 20px;
  display: flex;
  flex-direction: row;
}

.option-list-info-morethan-two {
  margin-top: 10px;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
}

.checkbox-option-list-info {
  margin-top: 10px;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
}

.select-container {
  position: relative;
  width: 100%;
  max-width: 300px;
  margin-left: 20px;
  margin-top: 20px;
}

.header-form-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.filtered_selector {
  position: relative;
  display: flex;
  max-width: 200px;
  height: 50px;
  width: 100%;
}
.fields-select-tag {
  width: 100%;
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 -4px 8px rgba(0, 0, 0, 0.1),
    4px 0 8px rgba(0, 0, 0, 0.1), -4px 0 8px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  appearance: none; /* Remove default dropdown arrow */
  -webkit-appearance: none;
  -moz-appearance: none;
  background-size: 12px;
}

.select-container select:focus {
  outline: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 -4px 8px rgba(0, 0, 0, 0.1),
    4px 0 8px rgba(0, 0, 0, 0.1), -4px 0 8px rgba(0, 0, 0, 0.1);
}

.select-container::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 15px;
  width: 12px;
  height: 12px;
  transform: translateY(-50%);
  pointer-events: none;
  outline: none;
}

select option {
  padding: 10px;
  font-size: 16px;
}

.input-element-field {
  border: none;
  color: #1b1919;
  font-size: 14px;
  font-weight: 600;
  height: inherit;
  outline: none;
  width: 100%;
}

.input-element-field::placeholder {
  color: rgb(204, 204, 204);
}

.field-info-container {
  /* width: 70%; */
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  margin-top: 20px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1), 0 -4px 4px rgba(0, 0, 0, 0.1),
    4px 0 4px rgba(0, 0, 0, 0.1), -4px 0 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.button-group {
  display: flex;
  width: 100%;
  padding: 8px 16px;

}

.button-yesorno {
  padding: 10px 20px;
  margin: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: white;
  cursor: pointer;
  /* width: 30%; */
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1), 0 -4px 4px rgba(0, 0, 0, 0.1),
    4px 0 4px rgba(0, 0, 0, 0.1), -4px 0 4px rgba(0, 0, 0, 0.1);
}

.button-yesorno:hover {
  background-color: #e0e0e0;
}

.button-yesorno.selected {
  background-color: #6a62d2;
  color: white;
  border-color: #6a62d2;
}

.dropdown-icon {
  position: absolute;
  top: 30%;
  right: 15px;
}

.patient-submit-loader{
  padding-left: 40px;
  padding-right: 40px;
}
