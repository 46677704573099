.popup {
    position: fixed;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border: 1px solid #ccc;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    text-align: center;
  }
  
  .popup h1 {
    margin-bottom: 10px;
  }
  
  .popup input {
    width: 80%;
    margin: 10px 0;
    padding: 5px;
    text-align: center;
  }
  
  .popup button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .popup button:hover {
    background-color: #0056b3;
  }
  
  .blast-background {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 999;
    pointer-events: none;
  }
  