/* Base Styles */
.right-side-bar {
  position: fixed;
  top: 60px; /* Adjust for header height */
  left: 120px;
  width: 210px;
  height: calc(100vh - 60px); /* Adjust for header height */
  overflow-y: auto;
  background-color: #fff;
  border-left: 1px solid #ddd;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  box-shadow: 4px 0 4px rgb(0 0 0 / 10%);
}

.right-side-bar::-webkit-scrollbar {
  display: none;
}

.right-side-bar.active {
  display: none;
}

.title-form-elements {
  font-size: 16px;
  margin-bottom: 20px;
  padding-left: 20px;
}

.each-form-element {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
}

.category-title {
  cursor: pointer;
  padding: 10px;
  border: 1px solid #ccc;
  font-weight: bold;
  border-radius: 30px;
  color: rgb(15, 160, 153);
  font-size: 1em;
  padding-left: 1em;
}

.category-items {
  padding: 10px;
}

.dnd-conatiner {
  display: flex;
  height: 100vh; /* Use viewport height */
  margin-right: auto;
}

.left-side-bar {
  position: relative;
  width: 70%;
  top: 20px;
  padding: 20px;
  margin-left: 180px; /* Ensure it doesn't overlap with the sidebar */
}

.droppable-container {
  height: 100%;
  box-shadow: 0 4px 6px 2px #bcbacf;
  padding: 20px;
  border-radius: 20px;
  overflow-y: auto; /* Enable scrolling if needed */
}

.section {
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.section:hover {
  border: 2px solid #6a62d2;
}

.section-title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
  border: none;
  outline: none;
}

.each-droppable-item {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 10px;
  background-color: #fff;
  border-top: 3px solid #6a62d2;
}

.drag-icon {
  width: 25px;
  height: 25px;
  opacity: 0.3;
  margin: auto;
  transform: rotate(90deg);
}

.remove-button-container {
  display: flex;
  margin-left: auto;
}

.clone-button {
  background-color: #6a62d2;
  color: #ffffff;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 12px;
  margin-right: 15px;
}
.remove-button {
  background-color: #ff4d4d;
  color: #ffffff;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 12px;
}

.drag-remove-conatiner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.required-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-right: 20px;
}

.required-chechbox {
  margin-right: 5px;
}

.header {
  position: fixed;
  top: 0;
  left: 120px;
  width: 90%;
  height: 70px;
  background-color: #fff;
  display: flex;
  align-items: center;
  z-index: 1000;
}

.form-builder-btn {
  border: none;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
  padding-left: 20px;
  font-size: 1.5em;
  display: none;
}
.form-builder-btn-plus {
  display: none;
}

.headline-text {
  font-size: 1.5em;
  font-weight: bold;
}

.form-builder-btn-plus.active {
  display: none;
}

.logo {
  display: block;
  height: auto;
  width: 80px;
  padding: 20px;
  margin-right: 15px;
}

.create-button-container {
  display: flex;
  gap: 10px;
  margin-left: auto;
  width:50%
}

.create_form_button,
.add_section_button {
  width: 120px;
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 -4px 8px rgba(0, 0, 0, 0.1),
    4px 0 8px rgba(0, 0, 0, 0.1), -4px 0 8px rgba(0, 0, 0, 0.1);
  appearance: none; /* Remove default dropdown arrow */
  -webkit-appearance: none;
  -moz-appearance: none;
  background-size: 12px;
}

.preview-section {
  width: 120px;
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 -4px 8px rgba(0, 0, 0, 0.1),
  4px 0 8px rgba(0, 0, 0, 0.1), -4px 0 8px rgba(0, 0, 0, 0.1);

}

.preview-button{
  border:none;
  outline: none;
  background-color: white;
  appearance: none; /* Remove default dropdown arrow */
  -webkit-appearance: none;
  -moz-appearance: none;
}


.create_form_button:hover,
.add_section_button:hover {
  background-color: #5a51dc;
  color: white;
}

.select-tag {
  width: 100%;
  padding: 12px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 -4px 8px rgba(0, 0, 0, 0.1),
    4px 0 8px rgba(0, 0, 0, 0.1), -4px 0 8px rgba(0, 0, 0, 0.1);
  appearance: none; /* Remove default dropdown arrow */
  -webkit-appearance: none;
  -moz-appearance: none;
  background-size: 12px;
  outline: none;
}

.select-clone-container select:focus {
  outline: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 -4px 8px rgba(0, 0, 0, 0.1),
    4px 0 8px rgba(0, 0, 0, 0.1), -4px 0 8px rgba(0, 0, 0, 0.1);

}

.select-clone-container {
  position: relative;
  width: 100%;
  max-width: 170px;
}

.select-clone-option {
  background: white;
  color: black;
}

.select-clone-option:hover{
  background-color: #5a51dc;
}

.short-text-icon-color,
.checkbox-icon-color,
.date-icon-color,
.long-text-icon-color,
.phone-number-icon-color,
.name-icon-color,
.gender-icon-color,
.number-icon-color,
.email-icon-color,
.select-icon-color,
.radio-icon-color,
.yesorno-icon-color {
  width: 24px;
  height: 24px;
}

.checkbox-icon-color {
  color: rgb(193, 92, 92);
}
.date-icon-color {
  color: #6e62bd;
}

.short-text-icon-color {
  color: purple;
}

.long-text-icon-color {
  color: green;
  font-weight: bold;
}

.phone-number-icon-color {
  color: rgb(15, 18, 160);
}

.name-icon-color {
  color: rgb(160, 126, 15);
}

.gender-icon-color {
  color: rgb(160, 15, 75);
}
.number-icon-color {
  color: rgb(160, 15, 119);
}
.email-icon-color {
  color: rgb(15, 160, 37);
}
.select-icon-color {
  color: rgb(15, 160, 153);
}

.radio-icon-color {
  color: rgb(114, 15, 160);
}

.yesorno-icon-color {
  color: rgb(15, 160, 114);
}

.imageupload-icon-color {
  color: rgb(160, 15, 119);
}

.file-icon-color {
  color: green;
}

.tooltip {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #7d79bc;
  color: white;
  padding: 10px;
  border-radius: 5px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.tooltip.show-tooltip {
  opacity: 1;
  visibility: visible;
}

/* Animation for tooltip */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.tooltip.show-tooltip {
  animation: fadeIn 0.5s forwards;
}

.form-details {
  padding: 20px;
  border-radius: 8px;
  margin: 0 auto;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px rgb(0 0 0 / 10%);
}

.form-input,
.form-textarea {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 18px;
}

.form-textarea {
  resize: none;
}

.form-input.error {
  border-color: red;
}

.error-message {
  color: red;
  margin-bottom: 10px;
}

.form-name-title {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
}

.form-input-bar {
  display: flex;
  flex-direction: column;
}

.form-description-title {
  margin: 10px 0 0;
  font-size: 16px;
  color: #555;
}

.section-related-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.field-settings {
  font-family: Arial, sans-serif;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #fff;
}

.field-settings .title {
  font-size: 16px;
  font-weight: bold;
}

.field-setting-label {
  font-size: 24px;

  font-weight: bold;
}

.field-setting-tittle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.field-settings .field {
  margin-bottom: 20px;
}

.field-settings .field label {
  display: block;
  margin-bottom: 5px;
  font-size: 14px;
  color: #333;
}

.field-settings .field input[type="text"],
.field-settings .field input[type="number"] {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 3px;
  outline: none;
}

.field-settings .toggle {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.field-settings .toggle label {
  font-size: 14px;
  color: #333;
  margin-right: 10px;
}

.field-settings .toggle .switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
}

.field-settings .toggle .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.field-settings .toggle .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.field-settings .toggle .slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

.field-settings .toggle input:checked + .slider {
  background-color: #00c7ff;
}

.field-settings .toggle input:checked + .slider:before {
  transform: translateX(14px);
}

.field-settings .placeholder {
  margin-bottom: 10px;
}

.field-settings .note {
  font-size: 12px;
  color: #999;
}

#close-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  font-weight: 500;
}

@media screen and (max-width: 768px) {
  .right-side-bar {
    position: relative;
    width: 100%;
    height: 300px;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1000;
    transform: translateY(100%);
    transition: transform 0.3s ease;
    overflow-y: auto;
    display: none;
  }

  .right-side-bar.active {
    display: flex;
  }

  .left-side-bar {
    width: 94%;
    margin-left: 0;
    margin-top: 10px;
    padding: 10px;
    margin-bottom: 250px; /* Space for the right-side bar when visible */
  }

  .cross-button {
    border: none;
    outline: none;
    background: #6a62d2;
    color: #fff;
  }

  .form-builder-btn {
    position: fixed; /* Position absolute within its relative parent */
    top: 10px;
    right: 10px;
    z-index: 1000;
    background: #6a62d2;
    color: white;
    padding: 10px;
    border-radius: 50%;
    font-size: 1.5em;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .form-builder-btn-plus {
    position: fixed; /* Position absolute within its relative parent */
    bottom: 20%;
    right: 45%;
    z-index: 1000;
    background: #6a62d2;
    color: white;
    padding: 10px;
    border-radius: 50%;
    font-size: 1.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: blink 1s infinite;
  }

  @keyframes blink {
    0% {
      background-color: #6a62d2; /* Start color */
    }
    50% {
      background-color: #8a82e6; /* Mid color */
    }
    100% {
      background-color: #6a62d2; /* End color */
    }
  }

  .header {
    top: 0; /* Keep the header at the top */
  }

  .form-details {
    padding: 10px;
  }

  .each-form-element {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
  }

  .category-title {
    font-size: 14px;
  }

  .category-items {
    padding: 5px;
  }

  .section {
    padding: 10px;
    margin-bottom: 15px;
  }

  .section-title {
    font-size: 16px;
    margin-bottom: 5px;
  }

  .each-droppable-item {
    padding: 10px;
    margin-bottom: 5px;
  }

  .drag-icon {
    width: 20px;
    height: 20px;
  }

  .remove-button {
    padding: 5px;
  }

  .required-container {
    margin-top: 5px;
  }

  .tooltip {
    top: 40%;
    font-size: 12px;
    padding: 5px;
  }
  .each-form-element {
    display: block;
  }

  .headline-text {
    font-size: 16px;
  }
  .logo {
    width: 50px;
  }

  .create_form_button,
  .add_section_button {
    width: auto;
    padding: 5px;
  }
  .form-name-title {
    font-size: 16px;
  }
  .section-title {
    font-size: 16px;
  }
}
