.graph-select {
  position: relative;
  /* max-width: 400px; */
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: end;
  margin-bottom: 20px;
}

.graph-select-tag {
  width: 150px;
  padding: 5px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 -4px 8px rgba(0, 0, 0, 0.1),
    4px 0 8px rgba(0, 0, 0, 0.1), -4px 0 8px rgba(0, 0, 0, 0.1);
  font-size: 12px;
  background-size: 12px;
  outline: none;
  border: none;
}

.aicvd-risk-block {
  padding: 20px;
  margin-top: 20px;
}

.patient-aicvd-score-block,
.patient-prediabetes-score-block {
  width: 49%;
  height: 280px;
  border-radius: 20px;
  background-color: white;
  padding: 20px;
}

.patient-total-attributes,
.patient-report-review {
  width: 100%;
  background-color: white;
  padding: 20px;
  border-radius: 20px;
}

.attributes-block {
  display: flex;
  /* justify-content: space-between; */
  gap: 30px;
  flex-wrap: wrap;
  padding: 10px;
}

.each-attribute {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 27%;
  padding: 5px;
}

.atribute-field-text {
  color: #00545e;
  font-weight: 400;
  font-size: 14px;
}

.attribute-data-field {
  color: #00545e;
  border: 1px solid #c7e4e8;
  width: 100px;
  min-height: 23px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  padding: 2px 8px;
  font-size: 13px;
  text-transform: capitalize;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
}

.attribute-tik-container {
  width: 20px;
  height: 20px;
  border: 1px solid #c7e4e8;

  border-radius: 3px;
  margin-left: 6px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.attribute-solid-bg {
  background-color: #26757e;
}
.direction-of-flex {
  flex-direction: row;
}

.block-6{
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 300px) and (max-width: 1024px) {
  .patient-aicvd-score-block,
  .patient-prediabetes-score-block {
    width: 100%;
    margin-bottom: 10px;
  }

  .each-attribute {
    width: 45%;
  }
}

@media screen and (max-width: 600px) {
  .attributes-block {
    padding: 0px;
  }

  .patient-aicvd-score-block {
    height: fit-content;
  }
  .direction-of-flex {
    flex-direction: column;
  }
  .each-attribute {
    width: 100%;
  }
}
