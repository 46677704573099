/* The switch - the container */
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  margin-right: 10px;
}

/* Hide the default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px; /* Rounded slider */
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: -4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%; /* Rounded circle */
}

input:checked + .slider {
  background-color: #6a62d2;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.status-update-container {
  display: flex;
}

.units-container {
  display: flex;
  margin-bottom: 10px;
}

.input-field-validation-items {
  border: none;
  outline: none;
  font-size: 16px;
  border-bottom: 1px solid gray;
  margin-left: 5px;
  width: 70%;
}

.units-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-top: 10px;
}

.units-fields-container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.units-fields-container label {
  font-weight: bold;
  margin-bottom: 5px;
}

.input-field-validation-items {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.input-field-validation-items:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

/* styles.css */
.more-options-container {
  position: relative;
}

.more-options-button {
  background: none;
  border: none;
  cursor: pointer;
  position: relative;
  margin-right:10px;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.dropdown-item {
  padding: 8px 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  background: none;
  border: none;
  text-align: left;
  width: 100%;
}

.dropdown-item svg {
  margin-right: 8px;
}


